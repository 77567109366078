// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import "~choices.js/src/styles/choices";

html, body {
    font-family: $font-family-sans-serif;
    background-color: #fff;
    position: relative;
    font-variant-numeric: tabular-nums;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
}

h3 {
    font-size: 1.25rem;
}

.strikethrough {
    text-decoration: line-through;
}

#logo {
    width: 5rem;
}

#logo-users {
    max-height: 5rem;
    max-width: 10rem;
}

.btn {
    border-radius: 0;
    padding: 0.7rem 1.5rem;
    border: 0;
    font-weight: bold;

    .fa-angle-right {
        margin-left: 1rem;
        position: relative;
        top: 1px;
    }

    &.btn-sm {
        padding: 0.25rem 0.5rem;
    }
}

.btn-primary {
    background-color: $green;
}

.btn-secondary {
    background-color: #9a9a9a;
}

.btn-outline-info:hover {
    color: $white;
}

.link-delete {
    border: none;
    background-color: transparent;
    padding: 0;
    color: $red;
    margin-left: 0.75rem;
}

.link-edit {
    color: $green;
}

.link-bold {
    font-weight: bold;
    text-decoration: none;
    color: $text-color;
}

.big-link {
    display: flex;
    align-items: center;
    background-color: #f8fafc;
    font-size: 1.2rem;
    padding: 1.5rem;
    color: $text-color;
    transition: all 0.4s ease;

    .far, .fal, .fas {
        font-size: 2rem;
        position: relative;
        bottom: 1px;
    }

    &:hover {
        color: $orange;
        background-color: #f6f6f6;
        text-decoration: none;
    }
}

.link-logout {
    color: #cd1719;
    font-size: 0.8rem;

    .far {
        margin-right: 0.25rem;
    }

    &:hover {
        color: #cd1719;
    }
}

/* SIDEBAR */

.sidebar {
    background-color: #f8fafc;
    border-right: 1px solid $border-color;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    padding: 1rem 1rem;
    min-height: auto;

    @include media-breakpoint-up(lg) {
        overflow: auto !important;
        position: fixed !important;
        height: 100vh;
    }

    .navbar-toggler {
        color: $text-color;
        font-size: 1.5rem;
    }
}

.menu-group {
    margin-top: 1rem;

    h3 {
        color: $orange;
        margin: 0;
    }

    ul {
        padding-left: 0;
        list-style: none;

        li {
            margin: 0.75rem 0;
        }
    }

    a {
        color: $text-muted;

        &:hover, &.active {
            color: $text-color;
            text-decoration: none;
            font-weight: bold;

            .far, .fas, .fal {
                color: $orange;
            }
        }

        .far, .fas, .fal {
            text-align: center;
            font-size: 1rem;
            width: 1.5rem;
            margin-right: 0.5rem;
        }
    }
}

main {
    padding: 1rem;
}

.anti-main {
    margin: -1rem;
}

header {
    padding: 1rem;
    background-color: #f8fafc;
}

.list-row {
    display: block;
    padding: 1.5rem 2rem;
    background-color: #f8fafc;
    color: $text-color;
    margin-bottom: 0.5rem;
    // transition: all 0.4s ease;

    &:hover {
        color: $text-color;
        background-color: #f6f6f6;
        text-decoration: none;
    }

    .fa-angle-right {
        color: $green;
        margin-left: 0.5rem;
        position: relative;
        top: 1px;
    }
}

.list-row-small {
    display: block;
    padding: 0.25rem 2rem;
    background-color: #f8fafc;
    color: $text-color;
    margin-bottom: 0.25rem;

    &:hover {
        color: $text-color;
        background-color: #f6f6f6;
        text-decoration: none;
    }
}

/* choices.js */
.choices__inner {
    border-radius: 0;
    border: 1px solid $text-color;
    background-color: #fff;
}
.is-open .choices__inner {
    border-radius: 0;
    border: 1px solid $text-color;
    background-color: #fff;
}
.choices__input {
    background-color: #fff;
}
.choices__list--multiple .choices__item {
    background-color: $primary;
    border: 1px solid $primary;
    border-radius: 0;
}

.form-control {
    border: 1px solid $text-color;
    padding: 0.6rem 1rem;
    border-radius: 0;
    height: auto;

    &:focus {
        border-color: #333;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.1);
    }

    &.form-control-max {
        max-width: 300px;
    }
}

textarea.form-control-sm {
    height: auto;
}

.block {
    padding: 2rem;
}

.block-grey {
    background-color: #f8fafc;
    margin: 2rem 0;
}

.option, .note {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    //input {
    //    width: calc(100% - 45px);
    //    margin-right: 0;
    //}

    button.remove-option, button.remove-attribute, button.remove-note {
        background-color: transparent;
        border: none;
        padding: 0.6rem 1rem;
        font-size: 0.9rem;
        color: $red;
    }

    &:first-of-type button.remove-option, button.remove-attribute {
        visibility: hidden;
    }
}

.product {
    background-color: #fff;
    padding: 1.5rem;
    position: relative;

    .delete-product {
        position: absolute;
        right: 1.5rem;
        top: 0.5rem;
        font-size: 0.8rem;
        display: none;

        &:hover {
            cursor: pointer;
        }
    }

    &:hover {
        .delete-product {
            display: inline-block;
        }
    }
}

.product-header, .product-footer {
    padding: 1rem 1.5rem;
    font-weight: bold;
    font-size: 1.1rem;
}

.product-footer {
    padding-top: 0;
}

/* Sets */
.set {
    background-color: $white;
    padding: 1.5rem;
    margin-bottom: 1rem;

    display: grid;
    grid-template-areas:
        "amount title"
        "properties properties"
        "contains contains"
        "products products"
        "totals totals"
        "controls controls";
    grid-template-columns: auto 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;

    .set-amount {
        grid-area: amount;
        font-size: $font-size-lg;
    }

    .set-title {
        grid-area: title;
        font-size: $font-size-lg;
        font-weight: bold;
    }

    .set-properties {
        grid-area: properties;
        display: flex;
        flex-direction: column;
    }

    .set-property {
        display: grid;
        grid-template-columns: minmax(30%, auto) 1fr;

        .set-property-name {
            font-weight: bold;
        }
    }

    .set-contains-title {
        grid-area: contains;

        font-size: $font-size-md;
        font-weight: bold;
    }

    .set-contains {
        grid-area: products;
        display: flex;
        flex-direction: column;

        .set-product {
            display: grid;
            grid-template-columns: minmax(10%, auto) 1fr;

            .set-product-title {
                font-weight: bold;
            }

            .set-product-differences {
                grid-column: 2 / -1;
                display: flex;
                flex-direction: column;

                .set-property-name {
                    margin-left: 1rem;
                    font-weight: normal;
                }
            }
        }
    }

    .set-totals {
        grid-area: totals;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        border-top: 1px solid $black;
        border-bottom: 2px solid $black;

        padding: 0.5rem 0;

        .set-subtotal-label, .set-subtotal-subtotal {
            width: 50%;
            min-width: max-content;

            text-align: center;
            font-size: $font-size-sm;
        }

        .set-total-amount, .set-total-total {
            width: 50%;
            min-width: max-content;

            text-align: center;

            font-size: $font-size-md;
        }

        .set-total-total {
            font-weight: bold;
        }
    }

    .set-controls {
        grid-area: controls;

        display: grid;
        grid-template-columns: auto 1fr;
        grid-column-gap: 1rem;
    }
}


.pagination {
    border-radius: 0;

    .page-link {
        background-color: #f6f6f6;
        border: none;
        margin-left: 0.25rem;
        color: #333;
    }

    .page-item.active .page-link {
        background-color: #cd1719;
    }
}


/* PRODUCT DROPDOWN */

.product-dropdown {
    background-color: #fff;
    max-height: 800px;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-top: 1rem;

    .product-dropdown-inner {
        padding: 0;
    }

    .dropdown-product {
        display: flex;
        align-items: center;
        padding: 0.5rem 1rem;
        border-bottom: 1px solid #efefef;

        &:hover {
            cursor: pointer;
            background-color: #efefef;
        }

        img {
            width: 4rem;
            height: auto;
        }

        .dropdown-product-content {
            margin-left: 1rem;
            width: calc(100% - 5rem);

            h4 {
                font-size: 1rem;
                margin-bottom: 0;
            }

            p {
                margin-bottom: 0;
            }
        }

        &:last-of-type {
            border-bottom: none;
        }
    }
}

/* SCHEDULE */

.scheduleAccordion {
    .alert {
        border-radius: 0;
    }

    .card {
        border-radius: 0;
    }

    .card-header {
        background-color: #f8fafc;
        padding: 0;

        .btn.btn-link {
            color: $text-color;
            padding: 0.8rem 1rem;

            &:focus, &.focus {
                outline: 0;
                box-shadow: none;
            }
        }
    }

    .card-body {
        padding: 1rem;
    }
}

.schedule-orders {
    $schedule_order_spacing: 1rem;
    $schedule_order_border: 2px;
    $schedule_order_border_color: #333;

    padding-left: $schedule_order_spacing;
    border-left: $schedule_order_border solid $schedule_order_border_color;

    .schedule-order {
        padding: .5rem;
        background-color: #efefef;
        position: relative;
        margin-bottom: $schedule_order_spacing / 2;
        &:nth-last-of-type(2) {
            margin-bottom: 0;
        }
        border: $schedule_order_border solid #efefef;

        &.highlight {
            border-color: #0056a4;
        }

        &:before {
            content: "";
            position: absolute;
            top: calc(50% - ($schedule_order_spacing / 2));
            left: calc((-1 * $schedule_order_spacing) - $schedule_order_border);
            width: 0;
            height: 0;
            // Triangle
            border-top: ($schedule_order_spacing / 2) solid transparent;
            border-bottom: ($schedule_order_spacing / 2) solid transparent;
            border-left: ($schedule_order_spacing / 2) solid $schedule_order_border_color;
        }

        .remove-order {
            transition: all 0.2s ease;

            &:hover {
                cursor: pointer;
                color: #ea0a0a
            }
        }
    }
}

#maps-chaffeur {
    height: 500px;
}

.stripped {
    background-color: transparent;

    &:nth-of-type(odd) {
        background-color: #fff;
    }
}

table.adp-directions {
    display: none !important;
}

.statistics-header, .statistics-row {
    padding: 0.25rem 0.5rem;
    display: block;
}

.statistics-header {
    font-weight: bold;
}

.statistics-row {
    color: $text-color;

    &:nth-child(even) {
        background-color: #f8fafc;
    }

    &:hover {
        color: $text-color;
        text-decoration: none;
        background-color: #f6f6f6;
    }
}

.statistics {
    padding: 0.75rem 1rem;
    background-color: #f8fafc;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    font-size: 2rem;
    line-height: 2rem;

    small {
        display: block;
        font-size: 0.75rem;
        line-height: 1rem;
        margin-bottom: 0.5rem;
    }

    &.statistics-totals {
        font-weight: bold;

        small:not(.statistics-extra) {
            font-weight: bold;
        }

        .statistics-extra {
            margin-top: .5rem;
        }
    }

    &.statistics-hover {
        &:hover {
            cursor: pointer;
        }
    }
}

.status-wait {
    color: #0d6efd;
    font-weight: bold;
}

.status-error {
    color: #dc3545;
    font-weight: bold;
}

.status-ok {
    color: #198754;
    font-weight: bold;
}

.badge-warehouse {
    background-color: #905800;
    color: #fff;
}

.draghandle {
    cursor: move;
}

.bg-retour, .list-row.bg-retour {
    background-color: #f9efef;
}

.add-product {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    width: 100%;

    img {
        width: 3rem;
        height: 2rem;
        object-fit: cover;
    }
}

.add-product-title {
    margin-left: 1rem;
}

.add-product:not(:last-of-type) {
    border-bottom: 1px solid #efefef;
}

.add-product-category-header {
    display: block;
    padding: 1rem;
    width: 100%;
    background-color: transparent;
    border: none;

    text-align: left;
    font-weight: bold;
    font-size: 1.1rem;

    position: relative;

    &::after {
        content: "\f078";
        font-family: "Font Awesome 5 Pro";

        position: absolute;
        right: 1rem;
    }

    &[aria-expanded=true]::after {
        content: "\f077";
    }
}

#addProductAccordion {
    .card-header {
        padding: 0;
    }

    .btn {
        padding: 0;
    }

    .card-body {
        padding: 0;
    }
}

.add-product-configurator {
    z-index: 2;

    background-color: white;
    width: 100%;
    height: 100%;
}

.product-configurator {
    padding: 15px;
}

.product-configurator-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    h2 {
        font-size: 1rem;
    }

    button {
        background-color: transparent;
        border: none;
        font-size: 1.2rem;
        padding: 0 10px;
    }
}

.stack {
    display: grid;

    > * {
        grid-area: 1 / 1 / 2 / 2;
    }
}

.text-bold {
    font-weight: bold;
}

@media (min-width: 992px) {
    .wrapper {
        padding-left: 18rem;
    }

    .sidebar {
        width: 18rem;
        padding: 2rem;
        position: absolute;
        min-height: 100vh;
    }

    #logo {
        width: 8rem;
    }


    #logo-users {
        max-height: 8rem;
        max-width: 15rem;
    }

    .anti-main {
        margin: -3rem;
    }

    main {
        padding: 3rem;
    }

    header {
        padding: 2rem 3rem 2rem 21rem;
    }

}

.hide-deselected-parent:has(input[type='checkbox']:not(:checked)) .hide-deselected {
    visibility: hidden;
}

.show-if-checked {
    display: none;
}

.show-if-checked-parent:has(.show-if-checked-check:checked) .show-if-checked {
    display: block;
}

.show-if-selected {
    display: none;
}

.show-if-selected-target {
    // Placeholder for target, not styled
}

.show-if-selected-parent:has(.show-if-selected-target:checked) .show-if-selected {
    display: block;
}

.columns {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(12em, 1fr));
}

input[type='number'][size='5'] {
    width: calc(5ch + 20px);
}

// Conditional show (empty message)
.show-if-first {
    display: none;
    &:first-child {
        display: initial;
    }
}

// Sortable
.sortable-drag, .sortable-fallback {
    opacity: 0 !important;
}

.sortable-ghost {
    outline: 2px solid $primary;
}

.gap-1 {
    gap: .25rem;
}

.gap-2 {
    gap: .5rem;
}

.gap-3 {
    gap: 1rem;
}

.scrollable-notes{
    max-height: 300px;
    overflow-y: auto;
}

// Attachments
.preview-img {
    width: 6rem;
    height: 4rem;
    object-fit: contain;
}
